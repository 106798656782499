import { BGZRoflScrimsDashboard } from "../pages/Dev/BGZRoflScrimsDashboard";
import { BGZTryouts } from "../pages/Dev/BGZTryouts";
import { Chart } from "../pages/Dev/Chart";
import { EsportsMatches } from "../pages/Dev/EsportsMatches";
import { GSMCRoflScrimsDashboard } from "../pages/Dev/GSMCRoflScrimsDashboard";
import { GSMCScrims } from "../pages/Dev/GSMCScrims";
import { GSMCScrimsDashboard } from "../pages/Dev/GSMCScrimsDashboard";
import { LolScouting } from "../pages/Dev/LolScouting";
import { MVGReplayScrimsDashboard } from "../pages/Dev/MVGReplayScrims";
import { RiotScouting } from "../pages/Dev/RiotScouting";
import { TNIScrims } from "../pages/Dev/TNIScrims";
import { TNIScrimsDashboard } from "../pages/Dev/TNIScrimsDashboard";
import { ValCT } from "../pages/Dev/ValCT";

export const routes = [
    {
        name: "BGZ Scrims",
        path: "/teams/bgz/scrims",
        component: <BGZRoflScrimsDashboard />,
        teams: ["BGZ"],
        roles: [],
        games: ["league"],
        isEnabled: false
    },
    {
        name: "BGZ Tryouts",
        path: "/teams/bgz/tryouts",
        component: <BGZTryouts />,
        teams: ["BGZ"],
        roles: [],
        games: ["league"],
        isEnabled: false
    },
    {
        name: "Chart",
        path: "/teams/chart",
        component: <Chart />,
        teams: [],
        roles: ["admin"],
        games: ["league"],
        isEnabled: true
    },
    {
        name: "Grid Esports",
        path: "/teams/grid/esports",
        component: <EsportsMatches />,
        teams: ["TNI", "GSMC", "FLK", "Esports"],
        roles: [],
        games: ["league"],
        isEnabled: true
    },
    {
        name: "GSMC Scrims",
        path: "/teams/gsmc/scrims",
        component: <GSMCScrims />,
        teams: ["GSMC"],
        roles: [],
        games: ["league"],
        isEnabled: false
    },
    {
        name: "GSMC ROFL Scrims",
        path: "/teams/gsmc/rofl-scrims-dashboard",
        component: <GSMCRoflScrimsDashboard />,
        teams: ["GSMC"],
        roles: [],
        games: ["league"],
        isEnabled: false
    },
    {
        name: "TNI Scrims",
        path: "/teams/tni/grid-scrims",
        component: <TNIScrims />,
        teams: ["TNI"],
        roles: [],
        games: ["league"],
        isEnabled: true
    },
    {
        name: "TNI Scrims Dashboard",
        path: "/teams/tni/scrims-dashboard",
        component: <TNIScrimsDashboard />,
        teams: ["TNI"],
        roles: [],
        games: ["league"],
        isEnabled: true
    },
    {
        name: "MVG Scrims",
        path: "/teams/mvg/scrims-replays",
        component: <MVGReplayScrimsDashboard />,
        teams: ["MVG"],
        roles: [],
        games: ["league"],
        isEnabled: false
    },
    {
        name: "LOL Scouting",
        path: '/teams/lol-scouting',
        component: <LolScouting />,
        teams: [],
        roles: [],
        games: ["league"],
        isEnabled: true
    },
    {
        name: "Riot Scouting",
        path: "/teams/riot-scouting",
        component: <RiotScouting />,
        teams: [],
        roles: [],
        games: ["league"],
        isEnabled: true
    },
    {
        name: "GSMC Scrims",
        path: "/teams/gsmc/scrims-dashboard",
        component: <GSMCScrimsDashboard />,
        teams: ["GSMC"],
        roles: [],
        games: ["league"],
        isEnabled: false
    },
    {
        name: "Val CT",
        path: "/teams/val-ct",
        component: <ValCT />,
        teams: [],
        roles: [],
        games: ["valorant"],
        isEnabled: false
    }
]